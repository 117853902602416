import { useState } from "react";
import assert from "assert";
import axios from 'axios';
import Config from "../../../Config";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Link, Outlet, useParams } from "react-router-dom";
import { ModalLink } from "../../../components/ModalLink";
import { useDeleteDeliveryGroupMutation, useGetDeliveryGroupsQuery } from "../../../gateways/cms/deliveryGroupApi";
import MainLayout from "../../../layouts/MainLayout";
import { EditMenuMinimum } from "../../../components/materials/EditMenuMinimum";
import { confirmWithStyle } from "../../../components/MyConfirm";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconPlus from "../../../assets/icons/icon_plus.svg";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const OrganizationEditTab = styled("ul")`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 16px;
    border-bottom: 8px solid #3A3E5D;

    li {
        width: 240px;
        height: 40px;
        text-align: center;
        margin: 0 4px 0 0;
        border-radius: 8px 8px 0 0;
        background-color: #DADDDE;
        overflow: hidden;
        line-height: 40px;
        
        a{
            display:block;
            height: 100%;
            color: inherit;
            text-decoration: none;
        }

        &.currentGroup,
        &:hover a{
            color: #fff;
            background-color: #3A3E5D;
        }
    }
`;
const OrganizationDeliveryGroupBody = styled("section")`
    display: flex;
    padding: 24px 24px calc(24px + 64px);

    & > nav {
        width: 335px;
        margin-right: 24px;

        header {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 4px 8px;
            border: 1px solid #DADDDE;
            background-color: #F4F4F4;
        }
        ul {

            li {
                position: relative;
                height: 56px;
                border: 1px solid #DADDDE;
                border-top-width: 0;

                & > a{
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 4px 8px;
                    text-decoration: none;
                    color: inherit;
                }
                &.active > a,
                & > a:hover{
                    color: #fff;
                    background-color: #3A3E5D;
                }
                & > div{
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
        & > a{
            display: block;
            height: 32px;
            width: 32px;
            min-width: 32px;
            margin: 16px auto;
            border: unset;
            border-radius: 2px;

            color: transparent;
            background-color: #3A3E5D;
            background-image: url(${iconPlus});
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 62.5%;

            overflow: hidden;
            user-select: none;
        }
    }
    & > main{
        flex: 1;
    }
`;
const OrganizationFormFooter = styled("footer")`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 260px);
    padding: 16px 24px;
    background-color: #F4F4F4;
`;
const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #C9CBD3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3A3E5D;
        border-color: #3A3E5D;
    }
`;

export function OrganizationDeliveryGroupListPage() {
    const { organizationId } = useParams();
    assert(organizationId);

    const [currentGroup, setCurrentGroup] = useState(-1);

    const deliveryGroups = useGetDeliveryGroupsQuery(organizationId);
    const [deliveryGroupDelete] = useDeleteDeliveryGroupMutation();

    /*** 未対応タスク数を取得する関数の定義 ***/
    // ヘッダー
    const token = useSelector((state: RootState) => state.auth.token);
    const instance = axios.create({
        baseURL: Config.baseEndpoint,
        headers: {
            Accept: "application/json, application/octet-stream",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    const handleDelete = (deliveryGroupId: number, deliveryGroupName: string) => async () => {
        const terminalCount = await instance.get(`terminal_count/${deliveryGroupId}`);
        
        if (typeof terminalCount.data === "number" && terminalCount.data > 0){
            alert(deliveryGroupName + "にはターミナルが存在するため削除できません");

        }
        else{
            const isConfirmed = await confirmWithStyle({
                title: "削除",
                body: "配信先を削除します。よろしいですか？",
                okButtonText: "削除",
                destructive: true,
            });
            if (isConfirmed) {
                await deliveryGroupDelete(deliveryGroupId);
                deliveryGroups.refetch();
            }
        }
    };
    const handleCurrentGroup = (deliveryGroupId: number) => () => {
        setCurrentGroup(deliveryGroupId);
    };

    return (
        <MainLayout title="テナント管理(編集)">

            <PageTitle>
                <h1>テナント管理（編集）</h1>
            </PageTitle>

            <OrganizationEditTab>
                <li>
                    <Link to={`/organizations/${organizationId}/edit`}>テナント情報</Link>
                </li>
                <li className="currentGroup">
                    配信グループ・端末管理
                </li>
            </OrganizationEditTab>

            <OrganizationDeliveryGroupBody>
                <nav>
                    <header>配信グループ</header>
                    <ul>
                        {deliveryGroups.data?.map((deliveryGroup) => (
                            <li key={deliveryGroup.id} className={`${currentGroup === deliveryGroup.id ? "active" : ""}`}>
                                <Link onClick={handleCurrentGroup(deliveryGroup.id)} to={`/organizations/${organizationId}/delivery-groups/${deliveryGroup.id}/terminals`}>
                                    {deliveryGroup.name}
                                </Link>
                                <EditMenuMinimum
                                    menuEdit={ <ModalLink to={`/delivery-groups/${deliveryGroup.id}/edit`}>編集</ModalLink> }
                                    menuDelete={ <button onClick={handleDelete(deliveryGroup.id, deliveryGroup.name)}>削除</button> }
                                />
                            </li>
                        ))}
                    </ul>
                    <ModalLink to={`/organizations/${organizationId}/delivery-groups/create`} >配信グループの追加</ModalLink>
                </nav>

                <main>
                    <Outlet />            
                </main>
            </OrganizationDeliveryGroupBody>

            <OrganizationFormFooter>
                <div className="footerLeft">
                </div>
                <div className="footerRight">
                    <Button type="button" variant="outlined"  href="/organizations">戻る</Button>
                </div>
        </OrganizationFormFooter>

        </MainLayout>
    );
}
